import { default as contactlsRwYzvf4oMeta } from "/home/michael/Desktop/michael-strain/pages/contact.vue?macro=true";
import { default as index3iBi4D6y46Meta } from "/home/michael/Desktop/michael-strain/pages/index.vue?macro=true";
import { default as privacyPolicyE5BmpAasvLMeta } from "/home/michael/Desktop/michael-strain/pages/legal/privacyPolicy.vue?macro=true";
import { default as termsAndConditionspYQeP4dBIVMeta } from "/home/michael/Desktop/michael-strain/pages/legal/termsAndConditions.vue?macro=true";
import { default as indexj8w1SAUAdYMeta } from "/home/michael/Desktop/michael-strain/pages/projects/farm/index.vue?macro=true";
import { default as indexISWD7O9UWBMeta } from "/home/michael/Desktop/michael-strain/pages/projects/harvest-valley/index.vue?macro=true";
import { default as indexIOsXnrMphhMeta } from "/home/michael/Desktop/michael-strain/pages/projects/index.vue?macro=true";
import { default as handsoGijmYgV2hMeta } from "/home/michael/Desktop/michael-strain/pages/projects/machines/hands.vue?macro=true";
import { default as indexxDW0bI3KbFMeta } from "/home/michael/Desktop/michael-strain/pages/projects/machines/index.vue?macro=true";
import { default as languagelNvW08UNfCMeta } from "/home/michael/Desktop/michael-strain/pages/projects/machines/language.vue?macro=true";
import { default as multi_45modelCrvniBJww1Meta } from "/home/michael/Desktop/michael-strain/pages/projects/machines/multi-model.vue?macro=true";
import { default as spreadsheet_45analyzerOyKWsmOQEgMeta } from "/home/michael/Desktop/michael-strain/pages/projects/machines/spreadsheet-analyzer.vue?macro=true";
import { default as scheduleCP4De1ib9SMeta } from "/home/michael/Desktop/michael-strain/pages/schedule.vue?macro=true";
export default [
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/michael/Desktop/michael-strain/pages/contact.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/michael/Desktop/michael-strain/pages/index.vue")
  },
  {
    name: "legal-privacyPolicy",
    path: "/legal/privacyPolicy",
    component: () => import("/home/michael/Desktop/michael-strain/pages/legal/privacyPolicy.vue")
  },
  {
    name: "legal-termsAndConditions",
    path: "/legal/termsAndConditions",
    component: () => import("/home/michael/Desktop/michael-strain/pages/legal/termsAndConditions.vue")
  },
  {
    name: "projects-farm",
    path: "/projects/farm",
    component: () => import("/home/michael/Desktop/michael-strain/pages/projects/farm/index.vue")
  },
  {
    name: "projects-harvest-valley",
    path: "/projects/harvest-valley",
    component: () => import("/home/michael/Desktop/michael-strain/pages/projects/harvest-valley/index.vue")
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/home/michael/Desktop/michael-strain/pages/projects/index.vue")
  },
  {
    name: "projects-machines-hands",
    path: "/projects/machines/hands",
    component: () => import("/home/michael/Desktop/michael-strain/pages/projects/machines/hands.vue")
  },
  {
    name: "projects-machines",
    path: "/projects/machines",
    component: () => import("/home/michael/Desktop/michael-strain/pages/projects/machines/index.vue")
  },
  {
    name: "projects-machines-language",
    path: "/projects/machines/language",
    component: () => import("/home/michael/Desktop/michael-strain/pages/projects/machines/language.vue")
  },
  {
    name: "projects-machines-multi-model",
    path: "/projects/machines/multi-model",
    component: () => import("/home/michael/Desktop/michael-strain/pages/projects/machines/multi-model.vue")
  },
  {
    name: "projects-machines-spreadsheet-analyzer",
    path: "/projects/machines/spreadsheet-analyzer",
    component: () => import("/home/michael/Desktop/michael-strain/pages/projects/machines/spreadsheet-analyzer.vue")
  },
  {
    name: "schedule",
    path: "/schedule",
    component: () => import("/home/michael/Desktop/michael-strain/pages/schedule.vue")
  }
]